import React, { useEffect, useState } from "react";
import './ethPayment.css';
import { Link, useParams } from "react-router-dom";
import qrCode from "../../../images/eth-qr-code-dynamic.png";
import btcQrCode from "../../../images/btc-qr-code-dynamic.png"

const EthPayment = () => {
  const { pmtMethod } = useParams();
  const [orderNumber, setOrderNumber] = useState(localStorage.getItem('jhlltd-order-num'));
  const [paymentMethod, changePmtMethod] = useState(localStorage.getItem('jhlltd-order-num-pmt'))
  const [costInGivenCrypto, setCostInGivenCrypto] = useState(null);
  const [costInUSD, setCostInUSD] = useState(null);
  const ethScAddress = "0xE839e5BE2c3E61b52B74DD4459B1a875420aE44B";
  const btcAddress = "bc1q0l2facndczk8fg7u6ysmcye8dqj8g2dhu2ssxs";

  const [copyState, changeCopyState] = useState(false);

  const handleCopyAddy = async () => {
    try {
      navigator.clipboard.writeText(pmtMethod === 'eth' ? ethScAddress : pmtMethod === 'btc' ? btcAddress : "");
      changeCopyState(true);
      setTimeout(() => {
        changeCopyState(false);
      }, 4000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const clearLocalOrder = () => {
    setOrderNumber('')
    changePmtMethod('')
    localStorage.removeItem('jhlltd-order-num')
    localStorage.removeItem('jhlltd-order-num-pmt')
  }

  const setLocalOrder = async (event) => {
    event.preventDefault();
    const orderNumber = event.target.elements.orderNumber.value;
    
      const response = await fetch(`/api/payment-method/${orderNumber}`)
      const data = await response.json();
      console.log(data)
      if (data.error) {
        console.error('Not a correct order number')
      } else {
        if (data === "Ethereum") {
          localStorage.setItem('jhlltd-order-num-pmt', 'eth');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
          changePmtMethod('eth')
            window.location.replace('/cart/checkout/eth');
        } else if (data === "Bitcoin") {
          localStorage.setItem('jhlltd-order-num-pmt', 'btc');
          localStorage.setItem('jhlltd-order-num', orderNumber);
          setOrderNumber(orderNumber);
          changePmtMethod('btc')
            window.location.replace('/cart/checkout/btc');
        }
      }
  };  
  

  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;

  useEffect(() => {
    const fetchCostInGivenCrypto = async () => {
      try {
        const response = await fetch(`/api/cost-in-crypto/${orderNumber}`);
        if (response.headers.get('content-type').includes('application/json')) {
          const data = await response.json();
          if (data.error) {
            setCostInGivenCrypto({ error: data.error });
          } else {
            setCostInGivenCrypto(data);
          }
        } else {
          throw new Error('Received non-JSON response');
        }
      } catch (error) {
        console.error('Error fetching cost in crypto:', error);
        setCostInUSD({ error: 'Failed to fetch cost in crypto' });
      }
    };
  
    const fetchCostInUSD = async () => {
  try {
    const response = await fetch(`/api/cost-in-usd/${orderNumber}`);
    console.log('response', response)
    if (response.headers.get('content-type').includes('application/json')) {
      const data = await response.json();
      console.log('data', data)
      if (data.error) {
        setCostInUSD({ error: data.error });
      } else {
        setCostInUSD(data);
      }
    } else {
      throw new Error('Received non-JSON response');
    }
  } catch (error) {
    console.error('Error fetching cost in USD:', error);
    setCostInUSD({ error: 'Failed to fetch cost in USD' });
  }
};

  
    fetchCostInGivenCrypto();
    fetchCostInUSD();
  }, [orderNumber, formattedDate]);
  
  

  return (
    <div className="eth-pmt-main">
      {orderNumber && paymentMethod === pmtMethod ? (
        <div className="order-pay-info-1">
          <div>
            <h1>Order Number: {orderNumber}</h1>
          </div>
          {costInGivenCrypto && costInGivenCrypto.error ? (
            <div className="error-message">
              <p>Cost in ${pmtMethod.toUpperCase()}: {costInGivenCrypto.error}</p>
            </div>
          ) : (
            <div className="order-pay-info-cost">
              <p>Cost in ${pmtMethod.toUpperCase()}: <span className="pulse-pmt-text order-pay-info-span-1">{pmtMethod === 'eth' ? costInGivenCrypto + orderNumber.slice(4, -5) : pmtMethod === 'btc' ? costInGivenCrypto + orderNumber.slice(4, -5) : ""}</span></p>
            </div>
          )}
          {costInUSD && costInUSD.error ? (
            <div className="error-message">
              <p>Cost in USD: {costInUSD.error}</p>
            </div>
          ) : (
            <div className="order-pay-info-cost">
              <p>Cost in USD: <span className="pulse-pmt-text order-pay-info-span-1">{costInUSD}</span></p>
            </div>
          )}
          <h1>SEND EXACT</h1>
          <div className="total-crypto-pmt-div">
            <div className="qr-pmt-div">
              <h3>Payment QR Code (Optional)</h3>
              <img id="pay-eth-qr-code" src={pmtMethod === 'eth' ? qrCode : pmtMethod === 'btc' ? btcQrCode : ""} alt="qrCodeAddress" />
            </div>
            <div className="addy-pmt-div">
              <div>
                <p>${pmtMethod.toUpperCase()} Address:</p>
                <span className="order-pay-info-span-2">{pmtMethod === 'eth' ? ethScAddress : pmtMethod === 'btc' ? btcAddress : ""}</span>
                <button onClick={handleCopyAddy}>Copy to Clipboard</button>
                <h5 id="coppied-to-clipboard" className={copyState ? 'coppied-popup' : 'hidden'}>Copied ✔</h5>
              </div>
            </div>
          </div>
          <h1>INFO</h1>
          <div className="bottom-pmt-eth-div">
            <p className="pmt-disclosure-p">Payment may take <span className="pmt-disclosure-span-ul">up to 48 hours</span> to settle. <span className="pmt-disclosure-span-bul">Your 
              funds are safe!</span> Once payment is verified you will receive an email for more information. <Link to={'mailto:Olympicpharma@proton.me'} className="pmt-disclosure-span-ul">Contact 
                us if you have any questions!</Link>
            </p>
          </div>
          <hr/>
          <div>
            <h2>Just Paid?</h2>
            <Link to='/order-status' className="pmt-bottom-btns-link">
              <button className="pmt-bottom-btns pulse-pmt-border">Check Order Status</button>
            </Link>
          </div>
          <hr/>
          <div>
            <h2>Have a different order?</h2>
            <button className="pmt-bottom-btns" onClick={clearLocalOrder}>Clear Order Number</button>
          </div>
        </div>
      ) : (
        <div className="order-not-found-div">
          <h1>No Order Number Found!</h1>
          <h2>Continue by giving us your order number</h2>
          <form className="order-num-form" onSubmit={setLocalOrder}>
            <input 
              name="orderNumber"
              className="order-num-input"
              placeholder="Enter Order Number"
              type="text"
            />
            <button className="order-num-button" type="submit">
              Send Number
            </button>
          </form>
          <h2>Don't have your order number?</h2>
          <h2>Email Us! <Link to='mailto:Olympicpharma@proton.me'>Olympicpharma@proton.me</Link></h2>
        </div>
      )}
    </div>
  );  
}

export default EthPayment;
