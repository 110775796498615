import vileOils from '../../images/vile v2.png'
import pillBottleOrals from '../../images/pill container v2.png'


const globalProducts =[ 
    {
        img: vileOils,
        name: "Zeus Blend",
        basePrice: 95,
        productId: 10001,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Tren A(100mg)/NPP(100mg)/Prop(100mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Basic Bulk",
        basePrice: 65,
        productId: 10002,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Prop(100mg)/NPP(100mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Achilles Blend",
        basePrice:90,
        productId: 10003,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Tren E(150mg)/Deca(150mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Hades PreWorkout",
        basePrice:90,
        productId: 10004,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Tren Base(30mg)/Halo(10mg)'
        ],
        carrierOil: []
       
      },
      {
        img: vileOils,
        name: "Poseidon Blend",
        basePrice: 80,
        productId: 10005,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Primo E(100mg)/Test (200mg)'
        ],
        carrierOil: []
        
      },
      {
        img: vileOils,
        name: "Classic Bulk",
        basePrice:85,
        productId: 10006,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: ['Test E(250mg)/Deca(200mg)'
        ],
        carrierOil: []
       
      },      
      {
        img: vileOils,
        name: "Ares Preworkout",
        basePrice: 100,
        productId: 10007,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'TestBase(30mg)/TrenBase(30mg)/Superdrol(10mg)/Halo(10mg)/M-Tren(2mg)'
        ],
        carrierOil: []
      },  
          
      {
        img: vileOils,
        name: "Lean Gains",
        basePrice:85,
        productId: 10008,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'EQ(200mg)/Mast E(100mg)/Tren E(50mg)'
        ],
        carrierOil: []
        
      },      
      {
        img: vileOils,
        name: "Apollo Blend",
        basePrice:110,
        productId: 10009,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Test P(100mg)/Mast P(100mg)/NPP(50mg)'
          ], 
          carrierOil: []
       
        },
      {
        img: vileOils,
        name: "Prep Blend",
        basePrice: 100,
        productId: 10010,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Mast E(100mg)/Primo E(100mg)'
        ],
        carrierOil: []
    
      },
      {
        img: vileOils,
        name: "Quick Bulk",
        basePrice: 70,
        productId: 10011,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Test P(250mg)/NPP(100mg)/(Deca200mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Shred Blend",
        basePrice: 100,
        productId: 10012,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Tren A(100mg)/Mast P(100mg)/Deca(100mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Shred Blend Long",
        basePrice: 90,
        productId: 10013,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Tren E(100mg)/Mast E(100mg)/Test E(50mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Hephaestus PreWorkout",
        basePrice: 80,
        productId: 10014,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: ['TestBase(30mg)/TrenBase(30mg)M-Tren(1mg)'],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Ultimate Bulk",
        basePrice: 65,
        productId: 10015,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Test P(100mg)/Anadrol(40mg)/Dbol(30mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Winter Gains Blend",
        basePrice: 65,
        productId: 10016,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'Deca(200mg)/Dbol(30mg)'
        ],
        carrierOil: []
      },
      {
        img: vileOils,
        name: "Hercules Blend",
        basePrice: 65,
        productId: 10017,
        category: 1,
        subCategory: 9,
        inStock: true,
        volume: ['10ml'],
        concentration: [
          'NPP(100mg)/Superdrol(20mg)'
        ],
        carrierOil: []
      },
      // {
      //   img: vileOils,
      //   name: "Ultimate Bulk",
      //   basePrice: 55,
      //   productId: 10018,
      //   category: 1,
      //   subCategory: 9,
      //   inStock: true,
      //   volume: ['170mg'],
      //   concentration: [
      //     '170mg'
      //   ],
      //   carrierOil: []
      // },
      // gay
      
      {
        img: vileOils,
        name: "Test E",
        basePrice: 40,
        productId: 10019,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: ['10ml','20ml'],
        concentration: ['250mg'],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Test E High",
        basePrice: 65,
        productId: 10020,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml',
          ],
        concentration: [
          '500mg'
        ],
        carrierOil: ["MCT",]
      },
      {
        img: vileOils,
        name: "Test C low",
        basePrice: 30,
        productId: 10021,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml',
          ],
        concentration: [
          '50mg'
        ],
        carrierOil: ["MCT",]
      },
      {
        img: vileOils,
        name: "Test C",
        basePrice: 40,
        productId: 10022,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml','20ml'
          ],
        concentration: [
          '200mg','250mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Test C High",
        basePrice: 70,
        productId: 10023,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '400mg'
        ],
        carrierOil: ["MCT",]
      },
      {
        img: vileOils,
        name: "Test P Low",
        basePrice: 30,
        productId: 10024,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml','20ml'
          ],
        concentration: [
          '100mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Test P",
        basePrice: 50,
        productId: 10025,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '200mg'
        ],
        carrierOil: ["MCT"]
      },
      {
        img: vileOils,
        name: "Test (No Ester)",
        basePrice: 70,
        productId: 10026,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '100mg'
        ],
        carrierOil: ["MCT"]
      },
      {
        img: vileOils,
        name: "Test+AI",
        basePrice: 70,
        productId: 10027,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '300mg'
        ],
        carrierOil: ["MCT"]
      },
      {
        img: vileOils,
        name: "Test U",
        basePrice: 50,
        productId: 10028,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '250mg'
        ],
        carrierOil: ["MCT",]
      },
      {
        img: vileOils,
        name: "Sustanon",
        basePrice: 50,
        productId: 10029,
        category: 1,
        subCategory: 1,
        inStock: true,
        volume: [
          '10ml',
          ],
        concentration: [
          '250mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Tren A",
        basePrice: 50,
        productId: 10030,
        category: 1,
        subCategory: 2,
        inStock: true,
        volume: [
          '10ml','20ml'
          ],
        concentration: [
          '100mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Tren A High",
        basePrice: 85,
        productId: 10031,
        category: 1,
        subCategory: 2,
        inStock: true,
        volume: [
          '10ml',
          ],
        concentration: [
          '200mg'
        ],
        carrierOil: ["MCT",]
      },
      {
        img: vileOils,
        name: "Tren E",
        basePrice: 60,
        productId: 10032,
        category: 1,
        subCategory: 2,
        inStock: true,
        volume: [
          '10ml','20ml'
          ],
        concentration: [
          '200mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Tren E High",
        basePrice: 100,
        productId: 10033,
        category: 1,
        subCategory: 2,
        inStock: true,
        volume: [
          '10ml',
          ],
        concentration: [
          '400mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Tren Base",
        basePrice: 65,
        productId: 10034,
        category: 1,
        subCategory: 2,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '50mg'
        ],
        carrierOil: ["MCT"]
      },
      {
        img: vileOils,
        name: "Mast E",
        basePrice: 60,
        productId: 10035,
        category: 1,
        subCategory: 3,
        inStock: true,
        volume: [
          '10ml','20ml'
          ],
        concentration: [
          '200mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Mast P",
        basePrice: 55,
        productId: 10036,
        category: 1,
        subCategory: 3,
        inStock: true,
        volume: [
          '10ml','20ml'
          ],
        concentration: [
          '100mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Mast P High",
        basePrice: 80,
        productId: 10037,
        category: 1,
        subCategory: 3,
        inStock: true,
        volume: [
          '10ml',
          ],
        concentration: [
          '200mg'
        ],
        carrierOil: ["MCT",]
      },
      // Deca
      {
        img: vileOils,
        name: "Deca",
        basePrice: 50,
        productId: 10038,
        category: 1,
        subCategory: 4,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '250mg'
        ],
        carrierOil: ["MCT", "GSO"]
      },
      {
        img: vileOils,
        name: "Deca Low",
        basePrice: 45,
        productId: 10039,
        category: 1,
        subCategory: 4,
        inStock: true,
        volume: [
          '10ml',
          ],
        concentration: [
          '200mg'
        ],
        carrierOil: ["MCT",]
      },
      {
        img: vileOils,
        name: "Deca High",
        basePrice: 70,
        productId: 10040,
        category: 1,
        subCategory: 4,
        inStock: true,
        volume: [
          '10ml'
          ],
        concentration: [
          '400mg'
        ],
        carrierOil: ["MCT"]
      },
      // NPP
        {
          img: vileOils,
          name: "NPP",
          basePrice: 45,
          productId: 10041,
          category: 1,
          subCategory: 5,
          inStock: true,
          volume: [
            '10ml',
            ],
          concentration: [
            '100mg'
          ],
          carrierOil: ["MCT", "GSO"]
        },
        {
          img: vileOils,
          name: "NPP High",
          basePrice: 75,
          productId: 10042,
          category: 1,
          subCategory: 5,
          inStock: true,
          volume: [
            '10ml',
            ],
          concentration: [
            '200mg'
          ],
          carrierOil: ["MCT"]
        },
        {
          img: vileOils,
          name: "EQ",
          basePrice: 50,
          productId: 10043,
          category: 1,
          subCategory: 6,
          inStock: true,
          volume: [
            '10ml'
            ],
          concentration: [
            '300mg'
          ],
          carrierOil: ["MCT", "GSO"]
        },
          {
            img: vileOils,
            name: "EQ High",
            basePrice: 70,
            productId: 10044,
            category: 1,
            subCategory: 6,
            inStock: true,
            volume: [
              '10ml'
              ],
            concentration: [
              '500mg'
            ],
            carrierOil: ["MCT"]
          },
          {
            img: vileOils,
            name: "Primo E",
            basePrice: 60,
            productId: 10045,
            category: 1,
            subCategory: 7,
            inStock: true,
            volume: [
              '10ml'
              ],
            concentration: [
              '100mg'
            ],
            carrierOil: ["MCT",]
          },
          {
            img: vileOils,
            name: "Primo E High",
            basePrice: 110,
            productId: 10046,
            category: 1,
            subCategory: 7,
            inStock: true,
            volume: [
              '10ml'
              ],
            concentration: [
              '200mg'
            ],
            carrierOil: ["MCT", "GSO"]
          },
          {
            img: vileOils,
            name: "DHB",
            basePrice: 50,
            productId: 10047,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '10ml',
              ],
            concentration: [
              '100mg'
            ],
            carrierOil: ["MCT"]
          },
          {
            img: vileOils,
            name: "Injectable Anadrol",
            basePrice: 35,
            productId: 10048,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '10ml','20ml'
              ],
            concentration: 
              [ '25mg', '50mg'],
              carrierOil:["MCT"]
          },
          {
            img: vileOils,
            name: "BAC Water",
            basePrice: 16,
            productId: 10049,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '30ml'
              ],
            concentration: 
              [ ],
              carrierOil: [],
          },
          {
            img: vileOils,
            name: "Sterile MCT",
            basePrice: 20,
            productId: 10050,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '10ml'
              ],
            concentration: 
              [],
              carrierOil:[]
          },
          {
            img: vileOils,
            name: "Injectable Dbol",
            basePrice: 50,
            productId: 10051,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '10ml','20ml'
              ],
            concentration: [
              '25mg', '50mg'
            ],
            carrierOil: ["MCT"]
          },
          {
            img: vileOils,
            name: "Injectable Superdrol",
            basePrice: 50,
            productId: 10052,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '10ml','20ml'
              ],
            concentration: [
              '10mg'
            ],
            carrierOil: ["MCT"]
          },
          {
            img: vileOils,
            name: "Injectable Winstrol",
            basePrice: 40,
            productId: 10053,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '10ml','20ml'
              ],
            concentration: [
              '30mg',
            ],
            carrierOil: ["MCT"]
          },
          {
            img: vileOils,
            name: "Ment",
            basePrice: 60,
            productId: 10054,
            category: 1,
            subCategory: 8,
            inStock: true,
            volume: [
              '10ml'
              ],
            concentration: [
              '30mg'
            ],
            carrierOil: ["MCT"]
          },
            {
              img: pillBottleOrals,
              name: "Anadrol",
              basePrice: 35,
              productId: 10055,
              category: 2,
              subCategory: 10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '25mg','50mg'
              ],
              carrierOil: []
              
            },
            {
              img: pillBottleOrals,
              name: "Anavar",
              basePrice: 30,
              productId: 10056,
              category: 2,
              subCategory: 10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '10mg','20mg','50mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Dianabol (Dbol)",
              basePrice: 50,
              productId: 10057,
              category: 2,
              subCategory: 10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '25mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Halotestin",
              basePrice: 95,
              productId: 10058,
              category: 2,
              subCategory: 10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '10mg',
              ],
              carrierOil: []
              
            },
            {
              img: pillBottleOrals,
              name: "Proviron ",
              basePrice: 50,
              productId: 10059,
              category: 10,
              subCategory: 10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '25mg',
              ],
              carrierOil: []
             
            },
            {
              img: pillBottleOrals,
              name: "Superdrol ",
              basePrice: 50,
              productId: 10060,
              category: 2,
              subCategory: 10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '10mg',
              ],
              carrierOil: []
              
            },
            {
              img: pillBottleOrals,
              name: "Turinbol ",
              basePrice: 45,
              productId: 10061,
              category: 2,
              subCategory: 10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '25mg',
              ],
              carrierOil: []
            
            },
            {
              img: pillBottleOrals,
              name: "Winstrol ",
              basePrice: 70,
              productId: 10062,
              category: 2,
              subCategory:10,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '25mg',
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Accutane",
              basePrice: 35,
              productId: 10063,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '20mg', '40mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Arimidex",
              basePrice: 80,
              productId: 10064,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '60ct'
                ],
              concentration: [
                '1mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Aromasin",
              basePrice: 70,
              productId: 10065,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '60ct'
                ],
              concentration: [
                '12.5mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Caber",
              basePrice: 130,
              productId: 10066,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '20ct'
                ],
              concentration: [
                '.5mg', '1mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Clenbuterol",
              basePrice: 60,
              productId: 10067,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '40mcg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Clomid",
              basePrice: 60,
              productId: 10068,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '60ct'
                ],
              concentration: [
                '50mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Finasteride",
              basePrice: 60,
              productId: 10069,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '60ct'
                ],
              concentration: [
                '1mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Letozole",
              basePrice: 50,
              productId: 10070,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '2.5mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Nolvadex",
              basePrice: 45,
              productId: 10071,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '20mg',
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Raloxifene",
              basePrice: 65,
              productId: 10072,
              category: 2,
              subCategory:11,
              inStock: true,
              volume: [
                '60ct'
                ],
              concentration: [
                '50mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Cialis",
              basePrice: 40,
              productId: 10073,
              category: 2,
              subCategory:12,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '10mg'
              ],
              carrierOil: []
            },
            {
              img: pillBottleOrals,
              name: "Viagra",
              basePrice: 60,
              productId: 10074,
              category: 2,
              subCategory:12,
              inStock: true,
              volume: [
                '30ct'
                ],
              concentration: [
                '100mg'
              ],
              carrierOil: []
            },
            // HGH / HCG / Peptides
            {
              img: vileOils,
              name: "BPC-157",
              basePrice: 40,
              productId: 10075,
              category: 3,
              subCategory:15,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '5mg'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "HCG",
              basePrice: 30,
              productId: 10076,
              category: 5,
              subCategory: 15,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '5,000iu'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "HGH Generic Black Tops (Kit)",
              basePrice: 160,
              productId: 10077,
              category: 3,
              subCategory: 13,
              inStock: true,
              volume: [
                '2ml (x10)'
                ],
              concentration: [
                '120iu'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "HGH Generic Black Tops(Single)",
              basePrice: 35,
              productId: 10078,
              category: 3,
              subCategory:13,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '12iu'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "HGH Generic Blue Tops (Kit)",
              basePrice: 150,
              productId: 10079,
              category: 3,
              subCategory:13,
              inStock: true,
              volume: [
                '2ml (x10)'
                ],
              concentration: [
                '100iu'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "HGH Generic Blue Tops (Single)",
              basePrice: 30,
              productId: 10080,
              category: 3,
              subCategory:13,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '10iu'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "IGF1_LR3",
              basePrice: 90,
              productId: 10081,
              category: 3,
              subCategory:15,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '1mg'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "IGF1_lR3 (Kit)",
              basePrice: 510,
              productId: 10082,
              category: 3,
              subCategory:15,
              inStock: true,
              volume: [
                '2ml (x10)'
                ],
              concentration: [
                '10mg'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "L-Carnitine",
              basePrice: 65,
              productId: 10083,
              category: 3,
              subCategory:15,
              inStock: true,
              volume: [
                '30ml'
                ],
              concentration: [
                '600mg'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "Semaglutide",
              basePrice: 85,
              productId: 10084,
              category: 3,
              subCategory:15,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '4mg'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "TB-500",
              basePrice: 45,
              productId: 10085,
              category: 3,
              subCategory:15,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '5mg'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "Tirzepatide",
              basePrice: 65,
              productId: 10086,
              category: 3,
              subCategory: 15,
              inStock: true,
              volume: [
                '2ml'
                ],
              concentration: [
                '5mg'
              ],
              carrierOil: []
            },
            {
              img: vileOils,
              name: "Sterile GSO Oil",
              basePrice: 20,
              productId: 10087,
              category: 1,
              subCategory: 8,
              inStock: true,
              volume: [
                '10ml'
                ],
              concentration: 
                [],
                carrierOil:[]
            },
            {
              img: vileOils,
              name: "M-Tren",
              basePrice: 60,
              productId: 10088,
              category: 1,
              subCategory: 8,
              inStock: true,
              volume: [
                '10ml'
                ],
              concentration: 
                ["2mg"],
                carrierOil:["MCT"]
            },
    ]

    export default globalProducts;