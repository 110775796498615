import React from "react";
import './footer.css'
import { Link } from "react-router-dom";


import OPLogo3 from '../../images/OPLogo4.png'

const Footer = () => {
  

  return (
    <div className="footer-div">
      <div className="footer-js-div">
        <h3>Olympian Pharmaceutical</h3>
      </div>
      <div className="footer-img-div"> 
        <img src={OPLogo3} alt="OPLogo"/>
      </div>
      <div className="footer-nav">
        {/* <h3></h3> */}
        <div>
          <Link className="footer-links" to="/steroid-profiles">
          <h3>Steroid Profiles</h3>
          </Link>
          <Link className="footer-links" to="/order-status">
          <h3>Order Status</h3>
          </Link>
        </div>
        <div>
          <Link className="footer-links" to="/restorepmt">
          <h3>Finish Payment</h3>
          </Link>
          <Link className="footer-links" to="/faqs">
          <h3>FAQs</h3>
          </Link>
          {/* <Link className="footer-links" to="/contact">
          <h3>Contact</h3>
          </Link> */}
        </div>
      </div>
    </div>
  )
}

export default Footer;