import React, { useState, useEffect } from "react";
import './indivProduct.css';
import { Link, useParams } from "react-router-dom";

import globalProducts from "../../Comps/Products/products";
import productsBackend from "../../Comps/Products/productBackend";
import prodSubCategories from "../../Comps/Products/productSubCategories";
import prodCategories from "../../Comps/Products/productCategories";
import { useCart } from "../../Comps/Cart/CartContext";

const IndivProduct = () => {
  const { subCatName, indivProductId } = useParams();
  const [prodBackendId, changeProdBackendId] = useState(indivProductId + '111');
  const [activeConcentration, setActiveConcentration] = useState(null);
  const [activeVolume, setActiveVolume] = useState(null);
  const [activeCarrierOil, setActiveCarrierOil] = useState(null);

  const updateProdBackendId = (concIndex, volIndex, carrIndex) => {
    const currentConcPart = prodBackendId.slice(indivProductId.length, indivProductId.length + 1);
    const currentVolPart = prodBackendId.slice(indivProductId.length + 1, indivProductId.length + 2);
    const currentCarrPart = prodBackendId.slice(indivProductId.length + 2, indivProductId.length + 3);
    
    
    const concPart = concIndex !== null && concIndex !== -1 ? String(concIndex + 1) : currentConcPart;
    const volPart = volIndex !== null && volIndex !== -1 ? String(volIndex + 1) : currentVolPart;
    const carrPart = carrIndex !== null && carrIndex !== -1 ? String(carrIndex + 1) : currentCarrPart;
  
    
  
    const newProdBackendId = indivProductId + concPart + volPart + carrPart;
    
    changeProdBackendId(newProdBackendId);
  };
  
  
  
  
  
  
  
  

  useEffect(() => {
    const product = globalProducts.find(globalItem => globalItem.productId.toString() === indivProductId);
    if (product) {
      if (product.concentration && product.concentration.length > 1) {
        setActiveConcentration(product.concentration[0]);
        updateProdBackendId(0, activeVolume !== null ? product.volume.indexOf(activeVolume) : null, activeCarrierOil !== null ? product.carrierOil.indexOf(activeCarrierOil) : null);
      }
      if (product.volume && product.volume.length > 1) {
        setActiveVolume(product.volume[0]);
        updateProdBackendId(activeConcentration !== null ? product.concentration.indexOf(activeConcentration) : null, 0, activeCarrierOil !== null ? product.carrierOil.indexOf(activeCarrierOil) : null);
      }
      if (product.carrierOil && product.carrierOil.length > 1) {
        setActiveCarrierOil(product.carrierOil[0]);
        updateProdBackendId(activeConcentration !== null ? product.concentration.indexOf(activeConcentration) : null, activeVolume !== null ? product.volume.indexOf(activeVolume) : null, 0);
      }
    }
  }, [indivProductId]);

  const handleConcentrationClick = (conc, index) => {
    setActiveConcentration(conc);
    updateProdBackendId(index, activeVolume !== null ? globalProducts[0].volume.indexOf(activeVolume) : null, activeCarrierOil !== null ? globalProducts[0].carrierOil.indexOf(activeCarrierOil) : null);
  };

  const handleVolumeClick = (vol, index) => {
    setActiveVolume(vol);
    updateProdBackendId(activeConcentration !== null ? globalProducts[0].concentration.indexOf(activeConcentration) : null, index, activeCarrierOil !== null ? globalProducts[0].carrierOil.indexOf(activeCarrierOil) : null);
  };

  const handleCarrierOilClick = (carr, index) => {
    setActiveCarrierOil(carr);
    updateProdBackendId(activeConcentration !== null ? globalProducts[0].concentration.indexOf(activeConcentration) : null, activeVolume !== null ? globalProducts[0].volume.indexOf(activeVolume) : null, index);
  };

  const { dispatch } = useCart();

  const addCartItem = (prodBackendId) => {
    
    const thisProduct = productsBackend.find(item => item.productBackendId.toString() === prodBackendId.toString());
    
    if (thisProduct) {
      dispatch({ type: 'ADD_ITEM', payload: thisProduct });
    } else {
      console.error('Product not found:', prodBackendId);
    }
  };

  return (
    <div className="indiv-prod-main">
      <Link to={`/store/${subCatName}`} className="indiv-prod-back-btn-link">
        <button className="indiv-prod-back-btn">{`Back <`}</button>
      </Link>
      {globalProducts
        .filter((globalItem) => globalItem.productId.toString() === indivProductId)
        .map((globalItem) => (
          <div key={globalItem.productId}>
            <div className="product-sort">
              <div className="left-indiv-prod">
                <img src={globalItem.img} alt={globalItem.name} />
              </div>
              <div className="right-indiv-prod">
                {productsBackend
                  .filter((items) => items.productFrontId.toString() === indivProductId)
                  .map((backendItem) =>
                    prodBackendId === backendItem.productBackendId.toString() ? (
                      <div key={backendItem.productBackendId}>
                        <h2>{backendItem.name}</h2>
                        <h2>{'$' + backendItem.price + '.00'}</h2>
                        {globalItem.concentration.length === 1 ? (
                          <h3>
                            Concentration: <span>{backendItem.concentration}</span>
                          </h3>
                        ) : (
                          <h3>
                            Concentration:
                            {globalItem.concentration.map((conc, index) => (
                              <button
                                key={index}
                                className={
                                  activeConcentration === conc
                                    ? 'prod-btn activeBtn'
                                    : 'prod-btn inactiveBtn'
                                }
                                onClick={() => handleConcentrationClick(conc, index)}
                              >
                                {conc}
                              </button>
                            ))}
                          </h3>
                        )}
                        {globalItem.volume.length === 1 ? (
                          <h3>
                            Volume: <span>{backendItem.volume}</span>
                          </h3>
                        ) : (
                          <h3>
                            Volume:
                            {globalItem.volume.map((vol, index) => (
                              <button
                                key={index}
                                className={
                                  activeVolume === vol
                                    ? 'prod-btn activeBtn'
                                    : 'prod-btn inactiveBtn'
                                }
                                onClick={() => handleVolumeClick(vol, index)}
                              >
                                {vol}
                              </button>
                            ))}
                          </h3>
                        )}
                        {globalItem.carrierOil.length === 0 ? (
                          ''
                        ) : globalItem.carrierOil.length === 1 ? (
                          <h3>
                            Carrier Oil: <span>{backendItem.carrierOil}</span>
                          </h3>
                        ) : (
                          <h3>
                            Carrier Oil:
                            {globalItem.carrierOil.map((carr, index) => (
                              <button
                                key={index}
                                className={
                                  activeCarrierOil === carr
                                    ? 'prod-btn activeBtn'
                                    : 'prod-btn inactiveBtn'
                                }
                                onClick={() => handleCarrierOilClick(carr, index)}
                              >
                                {carr}
                              </button>
                            ))}
                          </h3>
                        )}
                        <button
                          onClick={() => addCartItem(prodBackendId)}
                          className="prod-fin-add-cart"
                        >
                          Add To Cart
                        </button>
                      </div>
                    ) : null
                  )}
              </div>
            </div>
            <div>
            <div id="indiv-product-sec-1-hr">
              <hr />
            </div>
            <div className="indiv-products-details-div">
              <h2>Details</h2>
              <div className="indiv-products-details-inner">
                {/* <div>
                  <h3>Test Reports</h3>
                </div> */}
                {/* Steroid Profiles */}
                {globalProducts
        .filter((globalItem) => globalItem.productId.toString() === indivProductId)
        .map((globalItem) => (
            productsBackend
              .filter((items) => items.productFrontId.toString() === indivProductId)
              .map((backendItem) =>
                prodBackendId === backendItem.productBackendId.toString() ? 
              
              (
                <div className="indiv-bottom-info">
                  {backendItem.sProfile === 0 || backendItem.sProfile === undefined
                  ? '' 
                  :
                  <div>
                    <Link className="steroid-profile-link" to={'/steroid-profiles/' + backendItem.sProfile}>
                      <button>Steroid Profile</button>
                    </Link>
                  </div>}

                  {backendItem.description.length === 0 || backendItem.description.length === undefined ? '' :
                  (
                    <div>
                      <h3>Description</h3>
                      {backendItem.description.map((descriptions) => (
                        <p>{descriptions}</p>
                      ))}
                    </div>
                  )
                  }

                </div>
              )
              
              : null)
        ))
        }
              </div>
            </div>
            </div>
          </div>
        ))}
    </div>
  );

}
  

export default IndivProduct;
