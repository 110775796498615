import React from "react";
import './allSpecials.css'
import { Link, useParams } from "react-router-dom";

import globalProducts from "../../Comps/Products/products";
import prodSubCategories from "../../Comps/Products/productSubCategories";
import productsBackend from "../../Comps/Products/productBackend";
import { useCart } from "../../Comps/Cart/CartContext";

const AllSpecials = () => {
  const { specialName } = useParams();
  const { dispatch } = useCart()
  
  const specialsProducts = [
    // Weight Gain
    [globalProducts[39],globalProducts[40],globalProducts[36],globalProducts[37],globalProducts[49],globalProducts[55],globalProducts[46],globalProducts[53],globalProducts[50],globalProducts[58],globalProducts[41],globalProducts[42],globalProducts[43],globalProducts[44]],
    // Muscle Gain
    [globalProducts[43],globalProducts[44],globalProducts[28],globalProducts[29],globalProducts[30],globalProducts[31],globalProducts[36],globalProducts[37],globalProducts[38],globalProducts[41],globalProducts[42],globalProducts[33],],
    // Pre Workout
    [globalProducts[3],globalProducts[13],globalProducts[6]],
    // Quality Blends
    [globalProducts[0],globalProducts[2],globalProducts[3],globalProducts[4],globalProducts[6],globalProducts[8],globalProducts[13],globalProducts[16]],
    // Lab Tested
    [globalProducts[0]],
    // Weight Loss
    [globalProducts[84],globalProducts[82],globalProducts[65],globalProducts[28],globalProducts[29],globalProducts[51],globalProducts[60],globalProducts[54]]
  ]

  const addCartItem = (thisProduct) => {
    const thisCurrentProduct = productsBackend.find(item => item.productBackendId.toString() === (thisProduct.productId.toString() + '111'))
      dispatch({ type: 'ADD_ITEM', payload: thisCurrentProduct })
    return;
  }
  
  return (
    <div className="special-home-main">
      <h2 className="special-home-title">
        Top Products: <span>
          {specialName === 'weight-gain' ? " Weight Gain" : 
        specialName === 'muscle-gain' ? " Muscle Gain" :
        specialName === 'pre-workout' ? " Pre Workout" :
        specialName === 'quality-blends' ? " Quality Blends" :
        specialName === 'lab-tested' ? " Lab Tested" : 
        specialName === 'weight-loss' ? " Weight Loss" : 
        ''
        }
          </span>
      </h2>
      <div className="special-products-div">
        {specialsProducts[
          specialName === 'weight-gain' ? 0 : 
          specialName === 'muscle-gain' ? 1 :
          specialName === 'pre-workout' ? 2 :
          specialName === 'quality-blends' ? 3 :
          specialName === 'lab-tested' ? 4 : 
          specialName === 'weight-loss' ? 5 :
          0
        ].map((item) => (
          <div className="each-products">
            <Link 
              className="" 
              to={prodSubCategories
                .filter((cat) => item.subCategory.toString() === cat.subCatId.toString())
                .map(cat => 
                `/store/` + cat.subName + '/' + item.productId)
                .join('')
            }>
              <img src={item.img} alt={item.name}/>
            </Link>
            <h3>{item.name}</h3>
            <h2>${item.basePrice}.00</h2>
            <button onClick={() => addCartItem(item)}>
              Add To Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AllSpecials;