import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import './checkout.css';
import { useCart } from '../../Comps/Cart/CartContext';
import { EthereumPriceContext } from "../../Comps/CryptoPrice/ethPriceContext";
import { BitcoinPriceContext } from "../../Comps/CryptoPrice/btcPriceContext";

import allCountries from "../../Comps/Extras/countries";
import unitedStates from "../../Comps/Extras/unitedStatesStates";
import CouponCodes from "../../Comps/Extras/couponCodes";

import Spinner from "../../Comps/Extras/loadingIcon";
import pushCartToServer from "../../Comps/Firebase/pushCart";

import emailjs from 'emailjs-com';

const Checkout = () => {
  const { cart, totalCost, totalCostShipping, paymentMethod } = useCart();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [isUS, setIsUS] = useState(false);
  const [currentCoupon, changeCoupon] = useState(
    totalCost >= 1000 && totalCost < 2500 ? 
    {
      couponName: 'over$1000',isPercent: true,percentOff: 10,dollarOff: 0, minCost: 1000
    }
    : totalCost >= 2500 ? 
    {
      couponName: 'over$2500',isPercent: true,percentOff: 15,dollarOff: 0,minCost: 2500
    }
    : false
  );
  const [finalCost, changeFinalCost] = useState(
    totalCost >= 1000 && totalCost < 2500 ? totalCost * .9
    : totalCost >= 2500 ? totalCost *.85
    : totalCostShipping
  );
  const paymentMethodKeys = ["", "Bitcoin", "Ethereum"];
  const { dispatch } = useCart();

  const date = new Date();
  const timestamp = date.getTime();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;

  const [formData, setFormData] = useState({
    customerEmail: '',
    customerFName: '',
    customerLName: '',
    customerCountry: '',
    customerState: 'none',
    customerAddress: '',
    customerPostalCode: '',
    customerCity: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = (data) => {
    for (const key in data) {
      if (data[key] === undefined || data[key] === '') {
        throw new Error(`Error: set failed: value argument contains undefined in property '${formattedDate}.${timestamp}.userData.${key}'`);
      }
    }
  };

  const handlePushCart = async (event) => {
    event.preventDefault();
    const costInBtc = (finalCost / btcCurrentPrice).toFixed(5);
    const costInEth = (finalCost / ethCurrentPrice).toFixed(4);

    
    if (totalCost <150) {
      alert("Please add more items to your cart to continue (Total items < $150)")
    }

    try {
      validateFormData(formData);

      const clearCart = () => {
        dispatch({ type: 'CLEAR_CART' });
      };

      const emailParams = {
        first_name: formData.customerFName,
        last_name: formData.customerLName,
        email: formData.customerEmail,
        address: formData.customerAddress,
        country: formData.customerCountry,
        state: formData.customerState,
        postal_code: formData.customerPostalCode,
        total_cost: finalCost,
        total_cost_crypto: paymentMethod === 1 ? costInBtc : costInEth,
        payment_method: paymentMethod === 1 ? 'Bitcoin' : 'Ethereum',
        order_number: 'ORDER_NUMBER_PLACEHOLDER' // Replace with actual order number
      };



      if (paymentMethod === 1) {
        await pushCartToServer(cart, paymentMethodKeys[paymentMethod], finalCost, costInBtc, formData, currentCoupon);

        clearCart();
        window.location.replace('/cart/checkout/btc');
      } else if (paymentMethod === 2) {
        await pushCartToServer(cart, paymentMethodKeys[paymentMethod], finalCost, costInEth, formData, currentCoupon);
        
        clearCart();
        window.location.replace('/cart/checkout/eth');
      } else {
        await pushCartToServer(cart, paymentMethodKeys[paymentMethod], finalCost, formData, currentCoupon);
        clearCart();
      }
    } catch (error) {
      console.error(error.message);
      alert('An error occurred while processing your request. Please try again.');
    }
  };

  const ethPriceFrom = useContext(EthereumPriceContext)
  const ethCurrentPrice = ethPriceFrom !== null ? ethPriceFrom : 2500
  const btcPriceFrom = useContext(BitcoinPriceContext)
  const btcCurrentPrice = btcPriceFrom !== null ? btcPriceFrom : 59500

  const handleCountryChange = (event) => {
    const selectedCountryCode = event.target.value;
    const selectedCountryName = event.target.options[event.target.selectedIndex].text;
    setSelectedCountry(selectedCountryCode);
    setIsUS(selectedCountryCode === 'US');
    
    if (selectedCountryCode !== 'US') {
      setFormData({ ...formData, customerCountry: selectedCountryName, customerState: 'none' });
    } else {
      setFormData({ ...formData, customerCountry: selectedCountryName });
    }

  };

  const handleStateChange = (event) => {
    const selectedStateCode = event.target.value;
    const selectedStateName = event.target.options[event.target.selectedIndex].text;
    setSelectedState(selectedStateCode);
    setFormData({ ...formData, customerState: selectedStateName });
  };

  const handleCouponChange = (event) => {
    const couponTry = event.target.value.toLowerCase();
    const filteredCoupon = CouponCodes.find((coupon) => coupon.couponName && coupon.couponName.toLowerCase() === couponTry.trim());
    changeFinalCost(filteredCoupon === undefined ?
      totalCostShipping :
      filteredCoupon.minCost === 1000 && totalCost >= 1000 ? totalCostShipping * .9
      :
      filteredCoupon.minCost === 2500 && totalCost >= 2500 ? totalCostShipping * .85
      :
      filteredCoupon.isPercent === true && totalCost >= filteredCoupon.minCost ?
      totalCostShipping * (1 - (filteredCoupon.percentOff / 100))
      : filteredCoupon.isPercent === true ?
      totalCostShipping - filteredCoupon.dollarOff
      : totalCostShipping
    );
    changeCoupon(filteredCoupon && totalCost >= filteredCoupon.minCost ? filteredCoupon : false);
  };


  


  return (
    <div className="page-checkout-main">
    <div className="checkout-full-div">
      <div className="checkout-section-1">
      <form id="checkout-form" onSubmit={handlePushCart}>
      <h2 className="checkout-form-title">Shipping</h2>
      <div>
        <input 
          type="email" 
          name="customerEmail" 
          placeholder="Email" 
          required
          value={formData.customerEmail}
          onChange={handleChange}
        />
      </div>
      <div>
        <input 
          type="text" 
          name="customerFName" 
          placeholder="First Name"
          required
          value={formData.customerFName}
          onChange={handleChange}
        />
      </div>
      <div>
        <input 
          type="text" 
          name="customerLName" 
          placeholder="Last Name"
          required
          value={formData.customerLName}
          onChange={handleChange}
        />
      </div>
      <div className="country-select">
        <select 
          required 
          name="customerCountry" 
          className={selectedCountry === '' ? "customerCountryState" : "chosenCountryState"} 
          value={selectedCountry} 
          onChange={handleCountryChange}
        >
          <option value="">Select Country</option>
          {allCountries.map((country) => (
            <option 
              className="checkout-dropdown-items" 
              key={country.countryCode} 
              value={country.countryCode}
            >
              {country.countryName}
            </option>
          ))}
        </select>
      </div>
      {isUS && (
        <div className="us-states">
          <select 
            required 
            className={selectedState === '' ? "customerCountryState" : "chosenCountryState"} 
            name="customerState" 
            value={selectedState}
            onChange={handleStateChange}
          >
            <option value="">Select State</option>
            {unitedStates.map((state) => (
              <option 
                className="checkout-dropdown-items" 
                key={state.stateCode} 
                value={state.stateCode}
              >
                {state.stateName}
              </option>
            ))}
          </select>
        </div>
      )}
      <div>
        <input
          type="text"
          name="customerAddress"
          placeholder="Address"
          required
          value={formData.customerAddress}
          onChange={handleChange}
        />
      </div>
      <div>
        <input 
          type="text" 
          name="customerPostalCode" 
          placeholder="Postal Code"
          required
          value={formData.customerPostalCode}
          onChange={handleChange}
        />
      </div>
      <div>
        <input
          type="text"
          name="customerCity"
          placeholder="City"
          required
          value={formData.customerCity}
          onChange={handleChange}
        />
      </div>

          <h3 className="checkout-ready-to-pay">Ready to Finish?</h3>
          {paymentMethod === 1  
          && totalCost >= 150
          // && btcCurrentPrice !== null 
          ? 
          (
            <button type="submit" className="pay-now-btn">Pay Now!</button>
          )
          : paymentMethod === 2 
          && totalCost >= 150
          // && ethCurrentPrice !== null 
          ? 
          (
            <button type="submit" className="pay-now-btn">Pay Now!</button>
          )
          : totalCost <= 150 ? 
          <div>
            <h4>Go to the <Link to='/store'>store</Link> to add more items</h4>
          </div> 
          :
          <div className='loading-spinner-div'>
            <Spinner className='loading-spinner'/>
          </div>
          }
        </form>
        <div className="checkout-code-total">
          <h3 className="checkout-code-total-title">Have a code?</h3>
          <h4>Input one of our coupons codes here!</h4>
          <input
            type="text"
            name="couponCode"
            placeholder="Code!"
            onChange={handleCouponChange}
            defaultValue={
              totalCost >= 1000 && totalCost < 2500 ? 'over$1000'
              : totalCost >= 2500 ? 'over2500'
              : ""
            }
          />
          {currentCoupon ? (
            <div>
              <h4>{currentCoupon.couponName}</h4>
              {currentCoupon.isPercent ? (
                <h5>{"Discount: "}  
                  <span className="coupon-pulse"> {currentCoupon.percentOff}%</span>
                </h5>
              ) : (
                <h5>{"Discount: "}
                  <span className="coupon-pulse">${currentCoupon.dollarOff}</span>
                </h5>
              )}
            </div>
          ) : (
            <h4>No valid coupon code entered.</h4>
          )}
          {totalCost >= 150 
          ? 
          <div>
            <h2 className="checkout-order-total-title">Order Total</h2>
              {currentCoupon && currentCoupon.isPercent 
              ? 
              (
                <div>
                  <h3 className="checkout-total-cost-slash">
                    {"$" + totalCostShipping + '.00'}
                  </h3>
                  <h3 className="checkoutTotalPulse checkout-total-cost">
                    {"$" + finalCost}
                  </h3>
                </div>
              ) 
              : currentCoupon && !currentCoupon.isPercent 
              ? 
              (
                <div>
                  <h3 className="checkout-total-cost-slash">
                    {"$" + totalCostShipping + '.00'}
                  </h3>
                  <h3 className="checkoutTotalPulse checkout-total-cost">
                    {"$" + finalCost}
                  </h3>
                </div>
              ) 
              : 
              (
                <h3 className="checkoutTotalPulse checkout-total-cost">
                  {"$" + totalCostShipping + '.00'}
                </h3>
              )}
            {paymentMethod === 1 ? 
            (
              btcCurrentPrice !== null ? (
                <div>
                  <h2 className="checkout-order-total-title">ESTIMATED $BTC</h2>
                  <h3 className="checkout-total-cost">{(finalCost/btcCurrentPrice).toFixed(5)}</h3>
                </div>
              ) : (
                <div className='loading-spinner-div-r'>
                  <Spinner className='loading-spinner'/>
                </div>
              )
            ) : paymentMethod === 2 ?
            (
              ethCurrentPrice !== null ? (
                <div>
                  <h2 className="checkout-order-total-title">ESTIMATED $ETH</h2>
                  <h3 className="checkout-total-cost">{(finalCost/ethCurrentPrice).toFixed(4)}</h3>
                </div>
              ) : (
                <div className='loading-spinner-div-r'>
                  <Spinner className='loading-spinner'/>
                </div>
              )
            ) : (<h3>No Payment Method Selected</h3>)
          
          
          
          }
          </div> 
          :
          <div className="low-total-div">
            <h2 className="checkout-order-total-title-low">Order Total</h2>
            <h3 className="checkout-total-cost-low">
              <span>${totalCost}</span> is under the order minimum of <span>$150</span>. 
              Please add more items to your cart.
            </h3>
          </div>
        }
        </div>
      </div>


      {/* Responsive */}
      <h3 className="responsive-checkout-ready-to-pay">Ready to Finish?</h3>
      <div className="responsive-checkout-bottom-div">
          {paymentMethod === 1  
          // && btcCurrentPrice !== null 
          ? 
          (
            <button onClick={handlePushCart} type="button" className="responsive-pay-now-btn">Pay Now!</button>
          )
          : paymentMethod === 2 
          // && ethCurrentPrice !== null 
          ? 
          (
            <button onClick={handlePushCart} type="button" className="responsive-pay-now-btn">Pay Now!</button>
          )
          : 
          <div className='responsive-loading-spinner-div'>
            <Spinner className='loading-spinner'/>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Checkout;
