import React from "react";
import './about.css'


const About = () => {
  

  return (
    <div className="about-us-main">
      {/* Heading */}
      <div className="about-heading">
        <h1>About</h1>
      </div>

      {/* About Sections */}
      <div className="about-sections">
        <h5>Who Are We?</h5>
        <div>
          <p>
            Welcome to <span className="JHL-span">Olympian Pharmaceutical</span>, where we blend the art and science of bodybuilding with premium tested products. We understand that achieving your peak physical performance requires <span className="underline-span">more than just hard work</span>—it demands the right product tailored to your goals. With a passion for excellence and a commitment to quality, we meticulously formulate supplements that <span className="underline-span">support muscle growth, recovery, and overall strength.</span> Whether you're a seasoned athlete or just starting your fitness journey, our products harness the power of scientifically backed ingredients to help you push boundaries and achieve new heights. Join us at Olympian Pharmaceutical and unlock your potential in the gym and beyond. <span className="bold-span underline-span">Together, let's sculpt a stronger, healthier you.</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default About;