


const prodSubCategories = [
  {
    mainCategory: 1,
    subCatId: 1,
    subName: "Test"
  },
  {
    mainCategory: 1,
    subCatId: 2,
    subName: "Tren"
  },
  {
    mainCategory: 1,
    subCatId: 3,
    subName: "Mast"
  },
  {
    mainCategory: 1,
    subCatId: 4,
    subName: "Deca"
  },
  {
    mainCategory: 1,
    subCatId: 5,
    subName: "NPP"
  },
  {
    mainCategory: 1,
    subCatId: 6,
    subName: "EQ"
  },
  {
    mainCategory: 1,
    subCatId: 7,
    subName: "Primo"
  },
  {
    mainCategory: 1,
    subCatId: 8,
    subName: "Others"
  },
  {
    mainCategory: 1,
    subCatId: 9,
    subName: "Blends"
  },
  {
    mainCategory: 2,
    subCatId: 10,
    subName: "AAS"
  },
  {
    mainCategory: 2,
    subCatId: 11,
    subName: "Ancillaries"
  },
  {
    mainCategory: 2,
    subCatId: 12,
    subName: "Blood Flow Enhancement"
    // subName: "Blood Flow Enhancement (Boner Pills)"
  },
  {
    mainCategory: 3,
    subCatId: 13,
    subName: "HGH"
  },
  // {
  //   mainCategory: 4,
  //   subCatId: 14,
  //   subName: "HCG"
  // },
  {
    mainCategory: 5,
    subCatId: 15,
    subName: "Peptides"
  },
]

export default prodSubCategories;