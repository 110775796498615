import './App.css';
import axios from 'axios';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Header from './Comps/Header/header';
import Footer from './Comps/Footer/footer';
import Store from './Pages/Store/store';
import TestReports from './Pages/TestReports/testReports';
import About from './Pages/About/about';
import Cart from './Pages/Cart/cart';

import { CartProvider } from './Comps/Cart/CartContext';
import { EthereumPriceProvider } from './Comps/CryptoPrice/ethPriceContext';
import { BitcoinPriceProvider } from './Comps/CryptoPrice/btcPriceContext';
import { SolanaPriceProvider } from './Comps/CryptoPrice/solPriceContext';
import Checkout from './Pages/Cart/checkout';
import SubCats from './Pages/Store/subCat';

import globalProducts from './Comps/Products/products';
import prodSubCategories from './Comps/Products/productSubCategories';
import IndivProduct from './Pages/Store/indivProduct';
import Contact from './Pages/Contact/contact';
import FAQs from './Pages/FAQs/faqs';
import SteroidProfiles from './Pages/FAQs/steroidProfiles';
import AllSpecials from './Pages/Specials/allSpecials';
import IndivSteroidProfile from './Pages/FAQs/indivSteroidProfile'
import ScrollToTop from './Comps/Extras/scrollToTop'; // Adjust the path as needed
import EthPayment from './Pages/Payment/ethPayment/ethPayment';
import { useEffect, useState } from 'react';
import { initializeFirebase } from './Comps/Firebase/firebase';
import RestorePayment from './Pages/Payment/restorePayment/restorePayment';
import PostCheckout from './Pages/Payment/PostCheckout/postCheckout';
import IntData from './Pages/Behind/BTS';


function App() {

  return (
    <CartProvider>
    <EthereumPriceProvider>
    <BitcoinPriceProvider>
    <div>
      <Router>
      <ScrollToTop />
        <div className="flex-column justify-flex-start min-100-vh">
          <Header/>
          <div className="">
            <Routes>
              <Route 
                path="/"
                element={<Home />}
              />
              <Route 
                path="/cart"
                element={<Cart />}
              />
              {/* Checkout Crypto */}
                <Route 
                  path="/cart/checkout"
                  element={<Checkout />}
                />
                <Route 
                  path="/cart/checkout/:pmtMethod"
                  element={<EthPayment />}
                />
                <Route 
                  path="/restorepmt"
                  element={<RestorePayment />}
                />
                <Route 
                  path="/order-status"
                  element={<PostCheckout />}
                />


              <Route 
                path="/store"
                element={<Store />}
              />
              <Route 
                path='/store/:subCatName'
                element={<SubCats />}
              />
              <Route 
                path='/store/:subCatName/:indivProductId'
                element={<IndivProduct />}
              />


              
              
              <Route 
                path="/store/specials/:specialName"
                element={<AllSpecials />}
              />  


              
              <Route 
                path="/steroid-profiles"
                element={<SteroidProfiles />}
              />
              <Route 
                path="/steroid-profiles/:spId"
                element={<IndivSteroidProfile />}
              />
            



              {/* <Route 
                path="/test-reports"
                element={<TestReports />}
              /> */}
              <Route 
                path="/about"
                element={<About />}
              />
              <Route 
                path="/contact"
                element={<Contact />}
              />
              <Route 
                path="/faqs"
                element={<FAQs />}
              />
              <Route 
                path="/integrated-data"
                element={<IntData />}
              />
              <Route 
                path="*"
                element={<Home />}
              />
            </Routes>
          </div>
          <Footer/>
        </div>
      </Router>
    </div>
    </BitcoinPriceProvider>
    </EthereumPriceProvider>
    </CartProvider>
  );
}

export default App;
